
import { createMemo } from "solid-js";
import { Stack } from "@suid/material";
import { StarIcon, StarOutlineIcon, StarHalfIcon} from "../icons"

interface StarRatingProps {
  rating: number;
  size?: "small" | "inherit" | "large" | "medium"
}

const StarRating =({ rating, size = "small" }: StarRatingProps) => {
  // Ensure the rating is between 1 and 5
  const clampedRating = Math.min(Math.max(rating, 1), 5);
  const roundedRating = Math.round(rating * 2) / 2;

  // Generate an array with the desired number of StarIcon components
  const stars = createMemo(() => {
    const starElements = Array.from({ length: 5 }, (_, index) => {
      if (index < Math.floor(roundedRating)) {
        return <StarIcon fontSize={size} />;
      } else if (index < Math.ceil(roundedRating)) {
        return <StarHalfIcon fontSize={size} />;
      } else {
        return <StarOutlineIcon fontSize={size} />;
      }
    });

    return starElements;
  });

  return (
    <Stack direction="row" >
      {stars()}
    </Stack>
  );
}
export { StarRating }
